import { Controller } from "@hotwired/stimulus";

export default class extends Controller {
  static values = {
    email: {
      type: String,
      default: "",
    },
  };

  declare emailValue: string;

  // connect() {
  // }

  // disconnect() {
  // }

  submit(evt: Event) {
    if (!evt.defaultPrevented) {
      evt.preventDefault();
    }
    const link = this.element as HTMLAnchorElement;
    const params = new URLSearchParams();
    params.append("不具合のあったURL", window.location.href);
    params.append("ご利用環境", navigator.userAgent);
    params.append("メールアドレス", this.emailValue);

    const url = link.href + "?" + params.toString();

    window.open(url, "_blank");
  }
}
