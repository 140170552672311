import { Controller } from "@hotwired/stimulus";

export default class extends Controller<HTMLElement> {
  static values = {
    seed: String,
    length: Number,
  };

  static targets = ["input"];

  declare inputTarget: HTMLInputElement;
  declare seedValue: string;
  declare lengthValue: number;

  generate() {
    const password: string[] = [];
    const seed = this.seedValue;
    const len = this.lengthValue;
    for (let i = 0; i < len; i++) {
      const pos = getRandomInt(seed.length);
      password.push(seed.substring(pos, pos + 1));
    }
    console.info(password.join(""));
    this.inputTarget.value = password.join("");
  }
}

function getRandomInt(max) {
  return Math.floor(Math.random() * max);
}
