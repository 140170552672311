import {
  TurboSubmitEndEvent,
  TurboBeforeCacheEvent,
  TurboBeforeFrameRenderEvent,
  TurboBeforeRenderEvent,
  TurboBeforeStreamRenderEvent,
  TurboLoadEvent,
  TurboFrameRenderEvent,
  TurboRenderEvent,
  TurboBeforeVisitEvent,
  TurboVisitEvent,
} from "@hotwired/turbo";

type Unsubscribe = () => void;

function create<E extends Event>(type: string) {
  const on = (
    callback: (evt: E) => void,
    target: Document | Element = document,
    passive = true
  ) => {
    target.addEventListener(type, callback, { passive });
    return () => document.removeEventListener(type, callback);
  };

  const off = (
    callback: (evt: E) => void,
    target: Document | Element = document
  ) => {
    target.removeEventListener(type, callback);
  };

  const once = (
    callback: (evt: E) => void,
    target: Document | Element = document,
    passive = true
  ) => {
    const onceCallback = (evt: E) => {
      callback(evt);
      off(onceCallback, target);
    };
    return on(onceCallback, target, passive);
  };

  return [on, off, once] as const;
}

export const [onLoad, offLoad, onLoadOnce] =
  create<TurboLoadEvent>("turbo:load");
export const [onBeforeVisit, offBeforeVisit] =
  create<TurboBeforeVisitEvent>("turbo:before-visit");
export const [onVisit, offVisit] = create<TurboVisitEvent>("turbo:visit");
export const [onRender, offRender] = create<TurboRenderEvent>("turbo:render");
export const [onFrameRender, offFrameRender] =
  create<TurboFrameRenderEvent>("turbo:frame-render");
export const [onBeforeFrameRender, offBeforeFrameRender] =
  create<TurboBeforeFrameRenderEvent>("turbo:before-frame-render");
export const [onBeforeStreamRender, offBeforeStreamRender] =
  create<TurboBeforeStreamRenderEvent>("turbo:before-stream-render");
export const [onBeforeRender, offBeforeRender] = create<TurboBeforeRenderEvent>(
  "turbo:before-render"
);
export const [onBeforeCache, offBeforeCache] =
  create<TurboBeforeCacheEvent>("turbo:before-cache");

export const [onSubmitEnd, offSubmitEnd] =
  create<TurboSubmitEndEvent>("turbo:submit-end");

export function onSubmitSuccess(
  target: Element,
  callback: (evt: TurboSubmitEndEvent) => void
): Unsubscribe {
  return onSubmitEnd((evt) => {
    if (evt.detail.formSubmission.result?.success) {
      callback(evt);
    }
  }, target);
}
