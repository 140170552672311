import "@hotwired/turbo-rails";

import {
  onBeforeCache,
  onBeforeFrameRender,
  onBeforeRender,
} from "@/vendor/turbo";

// tooltip, popover, modal をキャッシュしない
onBeforeCache(() => {
  document
    .querySelectorAll<HTMLElement>(
      ".tooltip, .popover, .modal.show, .modal.in, .modal-backdrop"
    )
    .forEach((e) => {
      e.dataset.turboTemporary = "";
    });
  // modal は閉じていることにする
  document.body.classList.remove("modal-open");
});

// data-turbo-scroll-preserve attribute をつけると横スクロールを維持する
function scrollCacheRender(defaultRender) {
  return (currentBody: any, newBody: any) => {
    const caches: Record<string, number> = {};
    currentBody
      .querySelectorAll("[data-turbo-scroll-preserve][id]")
      .forEach((el) => {
        caches[el.id] = el.scrollLeft;
      });
    defaultRender(currentBody, newBody);
    document.body
      .querySelectorAll("[data-turbo-scroll-preserve][id]")
      .forEach((el) => {
        if (caches[el.id]) {
          el.scrollLeft = caches[el.id];
        }
      });
  };
}

onBeforeRender((event) => {
  event.detail.render = scrollCacheRender(event.detail.render);
});

onBeforeFrameRender((event) => {
  event.detail.render = scrollCacheRender(event.detail.render);
});
